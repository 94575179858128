import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/agent";
import { history } from "..";
import { message } from 'antd';


export default class BlogStore{
    blogList = [] ;

    get getBlogs() {        
        return this.blogList 
    }
    getBlog(id) {        
        return this.blogList && this.blogList.filter(blog => blog._id === id)
    }
    
    constructor(){
        makeAutoObservable(this)
    }
    loadBlogList = async () => {
        const data = await agent.blogs.get()
            .then(response => {
                runInAction(() => {            
                    if(response.data.message === 'Success'){
                        this.blogList = response.data.data
                    }
                });
                console.log("response.data",response.data.data)
            })
            .catch(error => {
                console.log("error", error.response ? error.response : error)
            })
    }
    createBlog = async (blog) => {
        const data = await agent.blogs.create(blog)
        try {
            if(data.data.message === "Success") {
                runInAction(() => {
                    message.success("Blog Created successfully", 1).then(() => {
                        history.push('/blogs');
                    })
                }); 
                return data.data           
            }
        }
        catch (error) {
            message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
            console.log("Blog Entry Error", error.response ? error.response : error)
            throw error
        }
    }
    updateBlog = async (id, blog) => {
        const data = await agent.blogs.update(id, blog)
            .then(response => {
                if(response.data.message === "Success") {
                    runInAction(() => {
                        message.success("Blog Updated successfully", 1).then(() => {
                            history.push('/blogs');
                        })
                    }); 
                    return response.data.data           
                }  
                console.log("response.data",response.data.data)
            })
            .catch(error => {
                message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
                console.log("error", error.response ? error.response.data.message : error)
            })
    }
    deleteBlog = async (id) => {
        const data = await agent.blogs.delete(id)
            .then(response => {
                if(response.data.message === "Success") {
                    runInAction(() => {
                        message.success("Blog Deleted Successfully", 1).then(() => {
                            window.location.reload();
                        })
                    });          
                }  
            })
            .catch(error => {
                message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
                console.log("error", error.response ? error.response.data.message : error)
            })
    }

    
}