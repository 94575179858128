import BlogList from "../../components/Blog/BlogList";
import { useStore } from "../../context/store";
import './Blog.css'
import { Link } from "react-router-dom";
import { PageHeader, Divider, Button} from 'antd';
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

const Blog = () => {
    const {blogStore} = useStore();

    const editBlog =(id) =>{
      console.log(id);
    }
    useEffect(()=>{
      blogStore.loadBlogList();
    },[])

    return (
      <div className="home">
        <PageHeader
                className="site-page-header"
                title="Blogs"
                subTitle="List of Blogs"
                extra={[
                  <Button key="3" type="primary">
                      <Link to="/addblog">
                          Add Blog
                      </Link>
                  </Button>,
                ]}
        />
        <Divider />
        <BlogList blogs={blogStore.blogList} editBlog={editBlog}/>
      </div>
    );
  }
export default observer(Blog);