import { Layout, Menu } from 'antd';
import {
  UnorderedListOutlined,
  BarChartOutlined,
  ProjectOutlined,
  AuditOutlined
} from '@ant-design/icons';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../../assets/logo.png';


export default function Sidebar({ collapsed }) {
  const { Sider } = Layout;

  const ImgStyle = {width: "140px", height: "123px"}
  const ImgStyle1 = {width: "70px", height: "53px"}

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width="250">
      <div className="logo">
        <Link to="/home">
          <img src={Logo} alt="Logo" style={collapsed === true ? ImgStyle1 : ImgStyle} />
        </Link>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[window.location.pathname]}>
        <Menu.Item key="/home" icon={<BarChartOutlined />}>
          <NavLink activeStyle={{color: "#ffffff"}} to='/home'>Home Page</NavLink>
        </Menu.Item>
        <Menu.Item key="/categories" icon={<UnorderedListOutlined />}>
          <NavLink activeStyle={{color: "#ffffff"}} to='/categories'>Categories</NavLink>
        </Menu.Item>
        <Menu.Item key="/products" icon={<ProjectOutlined />}>
          <NavLink activeStyle={{color: "#ffffff"}} to='/products'>Products</NavLink>
        </Menu.Item>
        <Menu.Item key="/blog" icon={<AuditOutlined />}>
          <NavLink activeStyle={{color: "#ffffff"}} to='/blogs'>Blogs</NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}
