import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Divider,PageHeader, Table, Space, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../context/store";
import { EditIcon, TrashIcon } from '../../components/UI/multiple-icons';

const HomePage = () => {
const { sliderStore } = useStore();
const history = useHistory();
const [sliders, setSliders] = useState([])

useEffect(() => {
  async function getSliders() {
    const result = await sliderStore.loadSliderList();
    setSliders(result)
  }

  getSliders();
}, []);

const confirmDelete = (_id) => {
  console.log(_id);
  sliderStore.deleteSlider(_id)
}

const cancelDelete = (e) => {
  console.log(e);
  message.error('Operation Canceled');
}

const columns = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render:  (t, r) => <img src={r.image_url} width="100px" height="80px" alt="Slider" />
    },
    {
      title: 'Heading',
      dataIndex: 'main_text',
      key: '_id',
    },
    {
      title: 'Sub Heading',
      dataIndex: 'sub_text',
      key: '_id',
    },    
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <span 
            style={{color: "#1890ff", cursor: "pointer"}} 
            onClick={() => { 
              history.push(`/update_slider/${record._id}`)
            }}>
              <EditIcon width={15} />
          </span>
          <Popconfirm
            title="Are you sure to delete this Slider?"
            onConfirm={() => confirmDelete(record._id)}
            onCancel={(e) => cancelDelete(e)}
            okText="Yes"
            cancelText="No"
          >
            <span style={{color: "crimson", cursor: "pointer"}}>
              <TrashIcon width={15} />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
      <div>
         <PageHeader
              className="site-page-header"
              title="Slides"
              subTitle="List of Slides"
              extra={[
                  <Button key="3" type="primary">
                      <Link to="/add_slider">
                          Add Slide
                      </Link>
                  </Button>,
              ]}
          />
          <Divider />
          <Row>
              <Col span={24}>
                <Table 
                  columns={columns} 
                  dataSource={sliders} 
                  pagination={{
                    pageSize:6,
                  }}
                />
              </Col>
          </Row> 
      </div>
  )
}


export default observer(HomePage);
