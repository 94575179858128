import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => {  
    
    const AdminToken = window.localStorage.getItem('adminToken');

    return(
        <Route
            {...rest}
            render={(props) => {
                if (AdminToken !== null) {
                    return <Component />;
                }else {
                    return <Redirect to='/' />;
                }
            }}
            />
    );
}