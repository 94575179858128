import { makeAutoObservable } from "mobx";

export default class CommonStore{
    token = window.localStorage.getItem('adminToken')
    apploading = false;
    
    constructor(){
        makeAutoObservable(this)
    }

    setToken = (token) =>{
        window.localStorage.setItem('adminToken', token)
        this.token= token
    }

    setAppLoaded =() =>{
        this.apploading= true;
    }
}