import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader, Row, Col, Form, Input, Button, Divider, message, Upload, Checkbox } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../context/store';

const EditSlider = () => {
    const { sliderStore } = useStore();
    const history = useHistory();
    const { id } = useParams();
    const selectedId = id;
    console.log("selectedId", selectedId);
    const filteredSlider = sliderStore.getSlider(selectedId);

    const [displayButtonFields, setDisplayButtonFields] = useState(filteredSlider[0] && filteredSlider[0].button.text !== "" && filteredSlider[0].button.link !== "" ? true : false);

    const formSubmit = async (values) => {
        console.log("Values", values);
        sliderStore.updateSlide(selectedId, {
            sub_text: values.subText,
            main_text: values.mainText,
            image_url: values.upload ? values.upload.response.data.url : filteredSlider[0] && filteredSlider[0].image_url,
            button: displayButtonFields === true ? {
                text: values.buttonText,
                link: values.buttonLink
            } : null
        })
    }

    const normFile = (e) => {
        console.log("Upload event:", e);
      
        if (Array.isArray(e)) {
          return e;
        }
      
        return e && e.file;
    };

    function onCheckAddButton() {
        // console.log(`checked = ${e.target.checked}`);
        setDisplayButtonFields(!displayButtonFields);
    }

    const imageProps = {
        name: "file",
        action: "https://api.rr-healthcare.com/api/v1/auth/upload",
        headers: {
          authorization: "authorization-text",
        },
        onChange(info) {
          if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        }
    };

    return (
        <div>
           <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title="Update Slider"
                subTitle="Here you can update slider"
            />
            <Divider />
                    <Form
                        id="category-form"
                        initialValues={{
                            mainText: filteredSlider[0] && filteredSlider[0].main_text,
                            subText: filteredSlider[0] && filteredSlider[0].sub_text,
                            buttonText: filteredSlider[0] && filteredSlider[0].button !== null ? filteredSlider[0].button.text: "",
                            buttonLink: filteredSlider[0] && filteredSlider[0].button !== null ? filteredSlider[0].button.link: ""
                        }}
                        onFinish={formSubmit}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 12 }}
                        layout="horizontal"                 
                    >
                        <Form.Item
                            name="mainText"
                            label="Heading"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="subText"
                            label="Sub Heading"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            name="upload"
                            label="Slider Image"
                            valuePropName="file"
                            getValueFromEvent={normFile}
                        >
                            <Upload {...imageProps}>
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="Add Button"
                        >
                            <Checkbox checked={displayButtonFields} onChange={onCheckAddButton} ></Checkbox>
                        </Form.Item>
                        {displayButtonFields && (
                        <div>
                            <Form.Item
                                label="Button Text"
                                name="buttonText"
                            >
                                <Input placeholder="Button Text" />
                            </Form.Item>
                            <Form.Item
                                label="Enter button link"
                                name="buttonLink"
                            >
                                <Input placeholder="Enter Page Link" />
                            </Form.Item>
                        </div>
                        )}
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 4,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>    
        </div>
    )
}

export default observer(EditSlider);
