import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader, Form, Input, Button, Divider, Select } from 'antd';
import { useStore } from '../../../context/store';

const CategoryEdit = () => {
    const { categoryStore } = useStore();
    const { Option } = Select;
    const { id } = useParams();
    const selectedId = id;

    console.log("selectedId", selectedId)

    const filteredCategory = categoryStore.categories &&  categoryStore.categories.filter(cat => cat._id === selectedId);

    const formSubmit = async (values) => {
        console.log("Values", values);

        categoryStore.updateCategory(selectedId, {
            name: values.name,
            description: values.description,
            parent: values.category === "none" ? "000000000000000000000000" : values.category
        })
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title="Update Category"
                subTitle="Here you can Update Category or Subcategory"
            />
            <Divider />
                    <Form
                        id="category-form"
                        initialValues={{
                            name: filteredCategory[0] && filteredCategory[0].name,
                            category: filteredCategory[0] && filteredCategory[0].parent,
                            description: filteredCategory[0] && filteredCategory[0].description
                        }}
                        onFinish={formSubmit}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 12 }}
                        layout="horizontal"                 
                    >
                        <Form.Item 
                            label="Name" 
                            name="name"
                            rules={[
                                {
                                required: true,
                                message: 'Please Enter Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Name" />
                        </Form.Item>  
                        <Form.Item 
                            label="Description" 
                            name="description"
                            rules={[
                                {
                                required: true,
                                message: 'Please Enter Description!',
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="Enter Description" rows={3} />
                        </Form.Item>
                        <Form.Item label="Category" name="category">
                            <Select>
                                {categoryStore.categories && categoryStore.categories.map(cat => (
                                    <Option value={cat._id} key={cat._id}>{cat.name}</Option>
                                ))}
                                <Option value="none" key={"321"}>None</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 12,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>   
        </div>
    )
}

export default observer(CategoryEdit);
