import React from "react";

const PageNotFound = () => {

    return (
        <React.Fragment>
            <div style={{marginTop: "50px", textAlign: "center", height: "100vh"}}>
                <div className="page-not-found-text">
                    <h1>*404*</h1>
                    <h2 style={{marginTop: "20px"}}>File not found</h2>
                    <h3 style={{marginTop: "20px"}}>This is not the web page you are looking for.</h3>
                    <h3 style={{marginTop: "20px"}}>The site configured at this address does not contain the requested file.</h3>
                    <button 
                        style={{padding: "5px 9px", marginTop: "20px", cursor: "pointer", fontWeight: "700"}}
                        onClick={() => window.history.back()}
                        type="button"
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PageNotFound;