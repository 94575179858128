import axios from 'axios/index';
import { store } from '../context/store';

axios.defaults.baseURL = 'https://api.rr-healthcare.com/api/v1';

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

const user = {
    login:(userlogin) => {
        return axios.post('/auth/signin', userlogin,{
            headers: {'Content-Type':'application/json'}
        })
    }
}
const imageUpload = {
    create:(data) => {
        return axios.post('/upload', data)
    }
}
const categories = {
    get:() => {
        return axios.get('/category/all')
    },
    create:(data) => {
        return axios.post('/category', data)
    },
    update:(id, data) => {
        return axios.patch(`/category/${id}`, data)
    },
    delete:(id) =>{
        return axios.delete(`/category/${id}`)
    }
}
const products = {
    get:() => {
        return axios.get('/product/all')
    },
    create:(data) => {
        return axios.post('/product', data)
    },
    update:(id, data) => {
        return axios.patch(`/product/${id}`, data)
    },
    delete:(id) =>{
        return axios.delete(`/product/${id}`)
    }
}
const blogs = {
    get:() => {
        return axios.get('/blog/all')
    },
    create:(data) => {
        return axios.post('/blog', data)
    },
    update:(id, data) => {
        return axios.patch(`/blog/${id}`, data)
    },
    delete:(id) =>{
        return axios.delete(`/blog/${id}`)
    }
}
const sliders = {
    get:() => {
        return axios.get('/slider/all')
    },
    create:(data) => {
        return axios.post('/slider', data)
    },
    update:(id, data) => {
        return axios.patch(`/slider/${id}`, data)
    },
    delete:(id) =>{
        return axios.delete(`/slider/${id}`)
    }
}
const agent = {
    user,
    imageUpload,
    products,
    blogs,
    sliders,
    categories
}


export default agent;