import { useState } from "react";
import { Form, Input, Button, Upload, PageHeader, Divider, message, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useStore } from "../../context/store";

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
};

const SliderForm = () => {
  const [displayButtonFields, setDisplayButtonFields] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const { sliderStore } = useStore();

  const onFinish = async (values) => {

    sliderStore.createSlide({
      main_text: values.mainText,
      sub_text: values.subText,
      button: displayButtonFields === false ? null : {
        text: values.buttonText,
        link: values.buttonLink
      },
      image_url: imageURL
    })
    
    console.log('form values',values);
    console.log('image url',imageURL);
    
  };

  const imageProps = {
    name: "file",
    action: "https://api.rr-healthcare.com/api/v1/auth/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setImageURL( info.file.response.data.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function onCheckAddButton(e) {
    console.log(`checked = ${e.target.checked}`);
    setDisplayButtonFields(e.target.checked);
  }
  return (
    <main>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Create Slider"
        subTitle="Here you can create slider"
      />
      <Divider />

      <Form name="validate_other" {...formItemLayout} onFinish={onFinish}>
        <Form.Item
          name="mainText"
          label="Heading"
          rules={[
            {
              required: true,
              message: "Please input text for slider image",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Enter Slider Heading" />
        </Form.Item>

        <Form.Item
          name="subText"
          label="Sub Heading"
          rules={[
            {
              required: true,
              message: "Please input description text for slider",
              whitespace: true,
            },
          ]}
        >
          <Input.TextArea placeholder="Enter Slider Sub Heading" />
        </Form.Item>
        <Form.Item
          name="upload"
          label="Image"
          rules={[
            {
              required: true,
              message: "Please upload image"
            },
          ]}
        >
          <Upload {...imageProps}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Add Button"
        >
          <Checkbox onChange={onCheckAddButton} ></Checkbox>
        </Form.Item>
        {displayButtonFields && (
          <div>
            <Form.Item
            label="Button Text"
            name="buttonText"
              rules={[{ required: true, message: "Missing button text" }]}
            >
              <Input placeholder="Enter Button Text" />
            </Form.Item>
            <Form.Item
            label="Button link"
            name="buttonLink"
              rules={[
                {
                  required: true,
                  message: "Missing link for button",
                },
              ]}
            >
              <Input placeholder="Enter Page Link" />
            </Form.Item>
          </div>
        )}

        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 4,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </main>
  );
};
export default SliderForm;
