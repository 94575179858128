import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStore } from '../../context/store';
import { PageHeader, Row, Col, Button, Divider, Table, Space, Popconfirm, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { EditIcon, TrashIcon } from '../../components/UI/multiple-icons';

function Products() {
    const { productStore } = useStore();
    const history = useHistory();

    useEffect(() => {
        productStore.loadProductList()

    }, []);

    const filteredProducts = productStore.productListWithoutTree !== null && productStore.productListWithoutTree.filter(pd => pd.is_product === true);

    const confirmDelete = (_id) => {
      console.log(_id);
      productStore.deleteProduct(_id)
    }
    
    const cancelDelete = (e) => {
      console.log(e);
      message.error('Changes Canceled');
    }

    const columns = [
      {
        title: "Images",
        dataIndex: "images_url",
        width: 50,
        maxWidth: 90,
        key: "_id",
        render:  (images_url) => <img src={images_url && images_url[0]} width="100px" height="80px" alt="Product" />,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: '_id',
      },
      {
        title: 'Category',
        dataIndex: ['parent_id', 'name'],
        key: '_id',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <span 
              style={{color: "#1890ff", cursor: "pointer"}}
              onClick={() => { 
                history.push(`/product_update/${record._id}`)
              }}
            >
              <EditIcon width={15} />
            </span>
            <Popconfirm
              title="Are you sure to delete this Product?"
              onConfirm={() => confirmDelete(record._id)}
              onCancel={(e) => cancelDelete(e)}
              okText="Yes"
              cancelText="No"
            >              
              <span style={{color: "crimson", cursor: "pointer"}}>
                <TrashIcon width={15} />
              </span>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Products"
                subTitle="List of Products"
                extra={[
                  <Button key="3" type="primary">
                      <Link to="/add_product">
                          Add Product
                      </Link>
                  </Button>,
                ]}
            />
            <Divider />
            <Row>
                <Col span={24}>
                  <Table 
                    columns={columns} 
                    dataSource={filteredProducts} 
                    pagination={{
                      pageSize:6,
                    }}
                  />
                </Col>
            </Row> 
        </div>
    )
}

export default observer(Products);
