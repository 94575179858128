import {Form, Input, Button, Checkbox, Row, Card} from 'antd';
import {useState,useEffect} from 'react';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import { useStore } from '../../context/store';
import './Login.css';


export default function Login() {
  const { userStore } = useStore();
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.
  const adminEmail = localStorage.getItem("admin-email");
  const [remember, setRemember] = useState(true)

  useEffect(() => {
    forceUpdate({});
  }, []);

    const onFinish = (values) => {
     console.log("form submit", values)
     userStore.login({ email: values.email, password: values.password }).then(() => {
        if(remember === true){
         localStorage.setItem("admin-email", values.email)
        }else if(remember === false){
          localStorage.removeItem("admin-email")
        }else{
          localStorage.removeItem("admin-email")
        }
     })
    };
    return (
      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white">
          <Row>
            <div className="login-box">
              <Card title='Login'>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    email: adminEmail,
                    password:''
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Username!',
                      },
                    ]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Enter Your Email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Enter Your Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember">
                      <Checkbox checked={remember} onChange={() => setRemember(!remember)}>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Row>
        </div>
      </section>
    )
}
