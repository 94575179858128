import { Layout } from 'antd';
import { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header'

export default function MainLayout(props) {
  const [collapsed, setCollapsed] = useState(false)
  const {Content, Footer} = Layout
   
  const toggle=() =>{
    setCollapsed(!collapsed)
  }

    return (
    <Layout>
      <Sidebar collapsed={collapsed}/>
      <Layout className="site-layout" style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}>
        
        <Header toggle={toggle} collapsed={collapsed}/>
        <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px 0',
              padding: '20px',
             height:'100vh' ,
              flex: '1 0 300px',
              overflow: 'auto',
            }}
          >
            {props.children}
        </Content>
        <Footer style={{ textAlign: 'center', paddingTop: "10px", paddingBottom: "10px" }}>RR HealthCare ©2021 Created by <a href="https://web55.tech" target="_blank">Web55 Technologies</a></Footer>
      </Layout>
    </Layout>
    )
}
