import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import { Router as Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import Routes from './Router';
import { StoreProvider } from './context/store';


export const history = createBrowserHistory();

ReactDOM.render(
  <StoreProvider>
    <Route history={history}>
      <Routes/>
    </Route>
  </StoreProvider>
  ,
  document.getElementById('root')
);


reportWebVitals();
