import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/agent";
import { message } from 'antd';
import { history } from "..";

export default class ProductStore{
    productListWithoutTree = [];
    categories = [];
    products = [];

    get getCategories() {
        return this.categories       
    }

    getSubCategories(id) {        
        return this.categories && this.categories.filter(subcat => subcat.parent_id._id === id)
    }

    getUnderSubCategories(id) {        
        return this.categories && this.categories.filter(sub => sub.parent_id._id === id)
    }

    constructor(){
        makeAutoObservable(this)
    }

    loadProductList = async () => {
        const data = await agent.products.get()
        .then(response => {
            runInAction(() => {            
                if(response.data.message === 'Success'){
                    this.productListWithoutTree = response.data.data
                    this.products = response.data.data.filter(pd => pd.is_product === true)
                    this.categories = response.data.data.filter(pd => pd.is_product === false)
                    console.log("LoadedProducts", response.data.data)
                }
            });
        })
        .catch(error => {
            console.log("error", error.response ? error.response : error)
        })
    }

    createProduct = async (entry) => {
        const data = await agent.products.create(entry)
        .then(response => {
            runInAction(() => {            
                if(response.data.message === 'Success'){
                    message.success(`${"Product Created Successfully"}`, 1)
                    history.push('/products') 
                }
            });
        })
        .catch(error => {
            console.log("error", error.response ? error.response : error)
            message.error(`${error.response ? error.response.data.message : "Something went wrong !"}`, 3)
        })
    }

    updateProduct = async (id, entry) => {
        const data = await agent.products.update(id, entry)
        .then(response => {
            if(response.data.message === "Success") {
                runInAction(() => {
                    message.success("Product Updated Successfully", 1).then(() => {
                        history.push('/products');
                    })
                }); 
                return response.data.data           
            }  
        })
        .catch(error => {
            message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
            console.log("error", error.response ? error.response.data.message : error)
        })
    }

    deleteProduct = async (id) => {
        const data = await agent.products.delete(id)
            .then(response => {
                if(response.data.message === "Success") {
                    runInAction(() => {
                        message.success("Product Deleted Successfully", 1).then(() => {
                            window.location.reload();
                        })
                    });          
                }  
            })
            .catch(error => {
                message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
                console.log("error", error.response ? error.response.data.message : error)
            })
    }
}