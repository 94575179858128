import { createContext, useContext } from "react";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import BlogStore from "./blogStore";
import SliderStore from "./sliderStore";
import CommonStore from "./commonStore";
import ProductStore from "./productStore";
import CategoryStore from "./categoryStore";

export const store = {
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    blogStore: new BlogStore(),
    sliderStore: new SliderStore(),
    commonStore: new CommonStore(),
    productStore: new ProductStore(),
    categoryStore: new CategoryStore()
}

export const StoreContext = createContext(store);

export const StoreProvider = (props) => {
    return (
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    )
}

export function useStore() {
    return useContext(StoreContext);
}

