import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/agent";
import list_to_tree from "../components/Tree/list_to_tree";
import { message } from 'antd';
import { history } from "..";

export default class CategoryStore{
    categoriesTree = [];
    categories = [];

    get getCategories() {
        return this.categories       
    }

    getSubCategories(id) {        
        return this.categories && this.categories.filter(subcat => subcat.parent === id)
    }

    getCategoryName(id) {        
        const filteredCategory = this.categories && this.categories.filter(cat => cat._id === id)
        return filteredCategory[0] && filteredCategory[0].name
    }

    constructor(){
        makeAutoObservable(this)
    }

    loadCategoryList = async () => {
        const data = await agent.categories.get()
        .then(response => {
            runInAction(() => {            
                if(response.data.message === 'Success'){
                    const topCategory = [{name: "RR-Healthcare Category Classification", description: "", parent: null, _id: "000000000000000000000000"}]
                    const combinedCategories = [...response.data.data, ...topCategory]
                    this.categoriesTree = list_to_tree(combinedCategories)
                    this.categories = combinedCategories
                    console.log("LoadedCategories", response.data.data)
                }
            });
        })
        .catch(error => {
            console.log("error", error.response ? error.response : error)
        })
    }

    createCategory = async (entry) => {
        const data = await agent.categories.create(entry)
        .then(response => {
            runInAction(() => {            
                if(response.data.message === 'Success'){
                    message.success(`${"Category Created Successfully"}`, 1)
                    history.push('/categories') 
                }
            });
        })
        .catch(error => {
            console.log("error", error.response ? error.response : error)
            message.error(`${error.response ? error.response.data.message : "Something went wrong !"}`, 3)
        })
    }

    updateCategory = async (id, entry) => {
        const data = await agent.categories.update(id, entry)
        .then(response => {
            if(response.data.message === "Success") {
                runInAction(() => {
                    message.success("Category Updated Successfully", 1).then(() => {
                        history.push('/categories');
                    })
                }); 
                return response.data.data           
            }  
        })
        .catch(error => {
            message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
            console.log("error", error.response ? error.response.data.message : error)
        })
    }
    deleteCategory = async (id) => {
        const data = await agent.categories.delete(id)
            .then(response => {
                if(response.data.message === "Success") {
                    runInAction(() => {
                        message.success("Category Deleted Successfully", 1).then(() => {
                            window.location.reload();
                        })
                    });          
                }  
            })
            .catch(error => {
                message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
                console.log("error", error.response ? error.response.data.message : error)
            })
    }
}