import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PageHeader, Form, Input, Button, Divider, Checkbox, Upload, message } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useStore } from '../../context/store';

const URL = "https://api.rr-healthcare.com/api/v1";

const UpdateBlog = (props) => {
  const { blogStore } = useStore();
  const history = useHistory();
  const { id } = useParams();
  const selectedBlogId = id;
  console.log("selectedBlogId", selectedBlogId)

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${URL}/upload`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("res:", res)
                resolve({
                  default: res.data.url
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const [body, setBody] = useState(null);

  const filteredBlogs = blogStore.getBlog(selectedBlogId)

  const [visible, setVisible] = useState(filteredBlogs[0] && filteredBlogs[0].is_link); 
  
  
  const imageProps = {
    name: "file",
    action: `${URL}/auth/upload`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const formSubmit = (values) => {
    console.log("Values", values);
    blogStore.updateBlog(selectedBlogId, {
      title: values.title,
      body: body,
      author: values.author, 
      image_url: values.image ? values.image.file.response.data.url : filteredBlogs[0] && filteredBlogs[0].image_url,
      publish_date: new Date(),
      is_link: visible,
      link: visible === true ? values.link : null
    })
  }

  return (
          <div>
            <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title="Update Blog"
                subTitle="Here you can Update Blog"
            />
            <Divider />
                    <Form
                        id="category-form"
                        initialValues={{
                            title: filteredBlogs[0] && filteredBlogs[0].title,
                            body: filteredBlogs[0] && filteredBlogs[0].body,
                            author: filteredBlogs[0] && filteredBlogs[0].author,
                            link: filteredBlogs[0] && filteredBlogs[0].link
                        }}
                        onFinish={formSubmit}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 12 }}
                        layout="horizontal"                 
                    >
                        <Form.Item label="Name" name="title">
                            <Input placeholder="Enter Blog Title" />
                        </Form.Item>
                        <div>
                          <div>                           
                            <Form.Item 
                              label="Image"
                              name="image"
                            >
                              <Upload {...imageProps}>
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                              </Upload>
                            </Form.Item>
                          </div>
                          {/* <div style={{marginLeft: "106px", marginBottom: "15px"}}>
                            <img src={imageUrl !== "" ? imageUrl : filteredBlogs[0] && filteredBlogs[0].image_url } width="100px" height="80px" alt="Blog" />
                          </div>  */}
                        </div>
                        <Form.Item label='External link'>
                            <Checkbox 
                              onChange={() => setVisible(!visible)}
                              checked={visible}
                            >
                              Yes
                            </Checkbox>
                        </Form.Item>
                        {visible === true ? (
                        <Form.Item label="Add Link" name="link">
                            <Input placeholder="Enter Link" />
                        </Form.Item>
                        ) : (
                        <Form.Item label="Description" name="body">
                          <CKEditor
                              editor={ ClassicEditor }
                              config={{ 
                                extraPlugins: [uploadPlugin] 
                              }}
                              data={filteredBlogs[0] && filteredBlogs[0].body}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                // console.log("Editor is ready to use!", editor);
                                editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                                });
                              }}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  setBody(data);
                                  // console.log( { event, editor, data } );
                                  console.log(body);
                              } }
                          />
                        </Form.Item>
                        )}
                        <Form.Item label="Author" name="author">
                            <Input placeholder="Enter Author Name" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 12,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>   
          </div>
  );
}
 
export default observer(UpdateBlog);