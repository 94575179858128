import { Route, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import PageNotFound from "./components/ProtectedRoutes/PageNotFound";
import SliderForm from "./components/Slider/AddSlider";
import Blog from "./pages/Blog";
import UpdateBlog from "./components/Blog/UpdateBlog"; 
import AddBlog from "./components/Blog/AddBlog";
import Categories from "./pages/Categories";
import AddProduct from "./pages/Products/AddProduct";
import Products from "./pages/Products";
import AddCategory from "./pages/Categories/AddCategory";
import CategoryEdit from "./pages/Categories/components/CategoryEdit";
import ProductEdit from "./pages/Products/components/ProductEdit";
import EditSlider from "./components/Slider/EditSlider";
import { ProtectedRoute } from "./components/ProtectedRoutes/ProtectedRoute";

const LayoutComponent = (Component) => {
  return (props) => (
    <MainLayout>
      <Component {...props} />
    </MainLayout>
  );
};

export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact={true} path='/' component={Login} />
        <ProtectedRoute path='/home' component={LayoutComponent(HomePage)} />
        <ProtectedRoute path='/add_slider' component={LayoutComponent(SliderForm)} />
        <ProtectedRoute path='/blogs' component={LayoutComponent(Blog)} />
        <ProtectedRoute path='/update_blog/:id' component={LayoutComponent(UpdateBlog)} />
        <ProtectedRoute path='/addblog' component={LayoutComponent(AddBlog)} />
        <ProtectedRoute path='/home' component={LayoutComponent(HomePage)} />        
        <ProtectedRoute path='/categories' component={LayoutComponent(Categories)} />
        <ProtectedRoute path='/add_category' component={LayoutComponent(AddCategory)} />
        <ProtectedRoute path='/add_product' component={LayoutComponent(AddProduct)} />
        <ProtectedRoute path='/products' component={LayoutComponent(Products)} />        
        <ProtectedRoute path='/category_update/:id' component={LayoutComponent(CategoryEdit)} />
        <ProtectedRoute path='/product_update/:id' component={LayoutComponent(ProductEdit)} />
        <ProtectedRoute path='/update_slider/:id' component={LayoutComponent(EditSlider)} />
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  )
}
