import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { PageHeader, Form, Input, Button, Divider, Select, message, Upload, Switch } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useStore } from '../../../context/store';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {toJS} from "mobx"

const URL = "https://api.rr-healthcare.com/api/v1";

const ProductEdit = () => {
    const { productStore, categoryStore } = useStore();
    const { id } = useParams();
    const { Option } = Select;
    const selectedId = id;
    console.log("selectedId", selectedId)

    useEffect(() => {
        categoryStore.loadCategoryList()
    }, [])
       
    const filteredProduct = productStore.products &&  productStore.products.filter(pd => pd._id === selectedId);

    const [imageLists, setImageLists] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [overview, setOverview] = useState(filteredProduct[0] && filteredProduct[0].overview);
    const [description, setDescription] = useState(filteredProduct[0] && filteredProduct[0].description);
    const [techspecs, setTechspecs] = useState(filteredProduct[0] && filteredProduct[0].tech_specs);
    const [certificates, setCertificates] = useState(filteredProduct[0] && filteredProduct[0].certification);
    const [visibleXS, setVisibleXS] = useState(filteredProduct[0] && filteredProduct[0].size_info[0] !== null && filteredProduct[0].size_info[0].size === 'XS' ? true : false);
    const [visibleS, setVisibleS] = useState(filteredProduct[0] && filteredProduct[0].size_info[1] !== null && filteredProduct[0].size_info[1].size === 'S' ? true : false);
    const [visibleM, setVisibleM] = useState(filteredProduct[0] && filteredProduct[0].size_info[2] !== null  && filteredProduct[0].size_info[2].size === 'M' ? true : false);
    const [visibleL, setVisibleL] = useState(filteredProduct[0] && filteredProduct[0].size_info[3] !== null && filteredProduct[0].size_info[3].size === 'L' ? true : false);
    const [visibleXL, setVisibleXL] = useState(filteredProduct[0] && filteredProduct[0].size_info[4] !== null && filteredProduct[0].size_info[4].size === 'XL' ? true : false);
    const xsRefNo = filteredProduct[0] && filteredProduct[0].size_info[0] !== null  && filteredProduct[0].size_info[0];
    const sRefNo = filteredProduct[0] && filteredProduct[0].size_info[1] !== null  && filteredProduct[0].size_info[1];
    const mRefNo = filteredProduct[0] && filteredProduct[0].size_info[2] !== null  && filteredProduct[0].size_info[2];
    const lRefNo = filteredProduct[0] && filteredProduct[0].size_info[3] !== null  && filteredProduct[0].size_info[3];
    const xlRefNo = filteredProduct[0] && filteredProduct[0].size_info[4] !== null  && filteredProduct[0].size_info[4];

    
    function uploadAdapter(loader) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file) => {
                body.append("file", file);
                fetch(`${URL}/upload`, {
                  method: "post",
                  body: body
                })
                  .then((res) => res.json())
                  .then((res) => {
                    console.log("res:", res)
                    resolve({
                      default: res.data.url
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }
      
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    const defaultImages = filteredProduct[0] && filteredProduct[0].images_url.map((iu, index) => ({
        uid: index,
        name: `Product Image ${index + 1}`,
        status: 'done',
        url: iu,
        response: {data: {url: iu}}
      }))

    const imageProps = {
        name: "file",
        action: "https://api.rr-healthcare.com/api/v1/auth/upload",
        headers: {
          authorization: "authorization-text",
        },
        onChange(info) {
          if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            setImageLists(info.fileList)
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onRemove(e) {
            setImageLists(imageLists.length !== 0 ? imageLists.filter(img => img.name !== e.name) : defaultImages.filter(img => img.name !== e.name))
        },
        defaultFileList: defaultImages
    };

   
    const formSubmit = async (values) => {
        console.log("Values", imageLists);
        productStore.updateProduct(selectedId, {
            name: values.name,
            overview: overview,
            description: description,
            tech_specs: techspecs,
            certification: certificates,
            images_url: imageLists.length !== 0 ? imageLists.map(fl => fl.response.data.url) : filteredProduct[0] && filteredProduct[0].images_url,
            parent_id: {_id: values.category, name: categoryName !== "" ? categoryName : filteredProduct[0] && filteredProduct[0].parent_id.name},
            size_info: [visibleXS ? {size: "XS", ref_no: values.XS} : null, visibleS ? {size: "S", ref_no: values.S} : null, visibleM ? {size: "M", ref_no: values.M} : null,
            visibleL ? {size: "L", ref_no: values.L} : null, visibleXL ? {size: "XL", ref_no: values.XL} : null],
            is_product: true
        })
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title="Update Product"
                subTitle="Here you can Update Product"
            />
            <Divider />
                    <Form
                        id="product-form"
                        initialValues={{
                            name: filteredProduct[0] && filteredProduct[0].name,
                            category: filteredProduct[0] && filteredProduct[0].parent_id._id,
                            XS: xsRefNo && xsRefNo.ref_no,
                            S: sRefNo && sRefNo.ref_no,
                            M: mRefNo && mRefNo.ref_no,
                            L: lRefNo && lRefNo.ref_no,
                            XL: xlRefNo && xlRefNo.ref_no,
                        }}
                        onFinish={formSubmit}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"                   
                    >
                        <Form.Item 
                            label="Name" 
                            name="name"
                        >
                            <Input placeholder="Enter Name" />
                        </Form.Item>  
                        <Form.Item label="Overview" name="overview">
                            <CKEditor
                              editor={ ClassicEditor }
                              config={{ 
                                extraPlugins: [uploadPlugin] 
                              }}
                              data={overview}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                                });
                              }}
                              onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setOverview(data);
                                console.log(overview);
                              }}
                          />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <CKEditor
                              editor={ ClassicEditor }
                              config={{ 
                                extraPlugins: [uploadPlugin] 
                              }}
                              data={description}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                                });
                              }}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  setDescription(data);
                                  console.log( { event, editor, data } );
                                  console.log(description);
                              } }
                          />
                        </Form.Item>

                        <Form.Item label="Technical Specs" name="technicalspecs">
                            <CKEditor
                              editor={ ClassicEditor }
                              config={{ 
                                extraPlugins: [uploadPlugin] 
                              }}
                              data={techspecs}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                                });
                              }}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  setTechspecs(data);
                                  console.log(techspecs);
                              } }
                          />
                        </Form.Item> 

                        <Form.Item label="Certification" name="certificates">
                            <CKEditor
                              editor={ ClassicEditor }
                              config={{ 
                                extraPlugins: [uploadPlugin] 
                              }}
                              data={certificates}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                );
                                });
                              }}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  setCertificates(data);
                                  console.log(certificates);
                              } }
                          />
                        </Form.Item>
                        <Form.Item label="Category" name="category">
                            <Select 
                                onChange={(value, event) => setCategoryName(categoryStore.getCategoryName(value))}
                            >
                                {categoryStore.categories && categoryStore.categories.map(cat => (
                                    <Option key={cat._id} value={cat._id}>{cat.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div className="sizes-area">
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>Sizes Info</label>
                            </div>
                        </div>   
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>XS</label>
                            </div>
                            <div className="ant-col ant-col-1.5 ant-form-item-label">
                                <Switch defaultChecked ={visibleXS} onChange={(checked) => setVisibleXS(checked)} />
                            </div>
                            <div className="ant-col ant-col-6 ant-form-item-label" style={{margin: "0 0 0 10px"}}>
                                {visibleXS && (
                                <Form.Item name="XS">                                  
                                    <Input placeholder="Enter XS Reference No." />
                                </Form.Item>
                                )}
                            </div>
                        </div> 
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>S</label>
                            </div>
                            <div className="ant-col ant-col-1.5 ant-form-item-label">
                                <Switch defaultChecked={visibleS} onChange={(checked) => setVisibleS(checked)} />
                            </div>
                            <div className="ant-col ant-col-6 ant-form-item-label" style={{margin: "0 0 0 10px"}}>
                                {visibleS && (
                                <Form.Item name="S">                                  
                                    <Input placeholder="Enter S Reference No." />
                                </Form.Item>
                                )}
                            </div>
                        </div>  
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>M</label>
                            </div>
                            <div className="ant-col ant-col-1.5 ant-form-item-label">
                                <Switch defaultChecked={visibleM} onChange={(checked) => setVisibleM(checked)} />
                            </div>
                            <div className="ant-col ant-col-6 ant-form-item-label" style={{margin: "0 0 0 10px"}}>
                                {visibleM && (
                                <Form.Item name="M">                                  
                                    <Input placeholder="Enter M Reference No." />
                                </Form.Item>
                                )}
                            </div>
                        </div>  
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>L</label>
                            </div>
                            <div className="ant-col ant-col-1.5 ant-form-item-label">
                                <Switch defaultChecked={visibleL} onChange={(checked) => setVisibleL(checked)} />
                            </div>
                            <div className="ant-col ant-col-6 ant-form-item-label" style={{margin: "0 0 0 10px"}}>
                                {visibleL && (
                                <Form.Item name="L">                                  
                                    <Input placeholder="Enter L Reference No." />
                                </Form.Item>
                                )}
                            </div>
                        </div> 
                        <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: "0px"}}>
                            <div className="ant-col ant-col-4 ant-form-item-label">
                                <label>XL</label>
                            </div>
                            <div className="ant-col ant-col-1.5 ant-form-item-label">
                                <Switch defaultChecked={visibleXL} onChange={(checked) => setVisibleXL(checked)} />
                            </div>
                            <div className="ant-col ant-col-6 ant-form-item-label" style={{margin: "0 0 0 10px"}}>
                                {visibleXL && (
                                <Form.Item name="XL">                                  
                                    <Input placeholder="Enter XL Reference No." />
                                </Form.Item>
                                )}
                            </div>
                        </div> 
                        </div> 

                        <Form.Item 
                            label="Image"
                            name="image"
                        >
                            <Upload {...imageProps}>
                                <Button icon={<UploadOutlined />}>Multiple upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 4,
                            }}
                            style={{ marginTop: "20px"}}
                        >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>   
        </div>
    )
}

export default observer(ProductEdit);
