import { makeAutoObservable, runInAction } from "mobx";
import agent from "../services/agent";
import { message } from 'antd';
import { history } from "..";


export default class SliderStore{
    sliderList = [] ;

    get getSliders() {        
        return this.sliderList 
    }
    getSlider(id) {        
        return this.sliderList && this.sliderList.filter(slider => slider._id === id)
    }
    
    constructor(){
        makeAutoObservable(this)
    }
    loadSliderList = async () => {
        try {
            const data = await agent.sliders.get()
            if(data.data.message === "Success") {
                runInAction(() => {
                    this.sliderList = data.data.data
                    console.log("Loaded Slide", data.data.data)
                }); 
                return data.data.data;             
            }  
        }
        catch (error) {
            console.log("GetBlogListError", error)
            throw error
        }
    }
    createSlide = async (slider) => {
        const data = await agent.sliders.create(slider)
        .then(response => {
            if(response.data.message === "Success") {
                runInAction(() => {
                    this.sliderList  = response.data.data
                    message.success("Slider Created Successfully", 1).then(() => {
                        history.push('/home');
                    })
                });
                return response.data         
            }  
        })
        .catch(error => {
            message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
            console.log("error", error.response ? error.response.data.message : error)
        })
    }
    updateSlide = async (id, slider) => {
        const data = await agent.sliders.update(id, slider)
        .then(response => {
            if(response.data.message === "Success") {
                runInAction(() => {
                    message.success("Slider Updated successfully", 1).then(() => {
                        history.push('/home');
                    })
                }); 
                return response.data.data           
            }  
        })
        .catch(error => {
            message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
            console.log("error", error.response ? error.response.data.message : error)
        })
    }
    deleteSlider = async (id) => {
        const data = await agent.sliders.delete(id)
            .then(response => {
                if(response.data.message === "Success") {
                    runInAction(() => {
                        message.success("Slider Deleted Successfully", 1).then(() => {
                            window.location.reload();
                        })
                    });          
                }  
            })
            .catch(error => {
                message.error(`${error.response ? error.response.data.message : "Something Went wrong"}`, 3);
                console.log("error", error.response ? error.response.data.message : error)
            })
    }
    
}