import { makeAutoObservable } from "mobx"

export default class ModalStore {
    open = false
    body = null
    title = ""

    constructor() {
        makeAutoObservable(this)
    }

    openModal = (content) => {
        this.open = true;
        this.body = content;

    }

    closeModal = () => {
        this.open = false;
        this.body = false;
        this.title = "";
    }
}