import React, { useEffect, useState } from 'react';
import { PageHeader, Row, Col, Form, Input, Button, Divider, Select, Checkbox } from 'antd';
import { useStore } from '../../context/store';
import { observer } from 'mobx-react-lite';

function AddCategory() {
    const { categoryStore } = useStore();
    const [form] = Form.useForm();
    const { Option } = Select;

    const [subCategoriesData, setSubCategoriesData] = useState([{ id: "", name: "", parent: "" }])
    const [subcategoryVisible, setSubcategoryVisible] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        categoryStore.loadCategoryList() 
    }, [])

    const onSelectCategory = (value, event) => {
        const subCategories = categoryStore.getSubCategories(value === null ? "" : value)
        if(subCategories.length !==0){
            setSubCategoriesData(subCategories.map(value => ({
                id: value._id, 
                name: value.name,
                parent: value.parent
            })))             
            setSubcategoryVisible(true)
        }else{
            setSubcategoryVisible(false)  
        }
    }

    const formSubmit = (values) => {
        console.log("Values:", values)

        if(values.subcatId && visible){ 
            categoryStore.createCategory({
                name: values.name,
                description: values.description,
                parent: values.parent !== "none" ? values.subcatId : "000000000000000000000000"
            })
        }else if(values.parent){
            categoryStore.createCategory({
                name: values.name,
                description: values.description,
                parent: values.parent !== "none" ? values.parent : "000000000000000000000000"
            })
        }else{
            categoryStore.createCategory({
                name: values.name,
                description: values.description,
                parent: "000000000000000000000000"
            })
        }
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title="Add Category"
                subTitle="Here you can Add Category or SubCategory"
            />
            <Divider />
                    <Form
                        form={form}
                        initialValues={{ remember: true }}
                        onFinish={formSubmit}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 12 }}
                    >
                        <Form.Item label="Name" name="name">
                            <Input placeholder="Enter Category Name" />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea placeholder="Enter Category Description" rows={3} />
                        </Form.Item>
                        <Form.Item label='Subcategory'>
                            <Checkbox onChange={() => setVisible(!visible)}>Yes</Checkbox>
                        </Form.Item>
                        {visible && <>
                        <Form.Item label="Parent" name="parent">
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Parent Category"
                                onChange={(value, event) => onSelectCategory(value, event)}
                            >
                                {categoryStore.categories && categoryStore.categories.map(cat => (
                                    <Option value={cat._id} key={cat._id}>{cat.name}</Option>
                                ))}
                                <Option value="none">None</Option>
                            </Select>
                        </Form.Item>
                        </>}
                        {subcategoryVisible && visible &&
                            <Form.Item 
                                label="Select Subcategory"
                                name="subcatId"
                            >
                                <Select
                                    placeholder="Select Subcategory"
                                >
                                    {subCategoriesData.map(subcat => (
                                        <Option value={subcat.id} key={subcat.id}>{subcat.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
        </div>
    )
}

export default observer(AddCategory);
