import { useState } from "react";
import { useHistory } from "react-router";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PageHeader, Divider, Form, Input, Button, message, Upload, Checkbox } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useStore } from "../../context/store";

const URL = "https://api.rr-healthcare.com/api/v1";

const AddBlog = () => {
    const history = useHistory();
    const { blogStore } = useStore();
    const [body, setBody] = useState(null);
    const [visible, setVisible] = useState(false); 
    const adminToken = window.localStorage.getItem('adminToken') 
    const authorization = { Authorization: `Bearer ${adminToken}` }

    function uploadAdapter(loader) {
      return {
        upload: () => {
          return new Promise((resolve, reject) => {
            const body = new FormData();
            loader.file.then((file) => {
              body.append("file", file);
              fetch(`${URL}/auth/upload`, {
                method: "post",
                body: body,
                // mode: "no-cors",
                headers: {authorization}
              })
                .then((res) => res.json())
                .then((res) => {
                  console.log("res:", res)
                  resolve({
                    default: res.data.url
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            });
          });
        }
      };
    }

    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }

    const imageProps = {
      name: "file",
      action: "https://api.rr-healthcare.com/api/v1/auth/upload",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
  
    const handleSubmit = (values) => {
      const blog = { 
        title: values.title, 
        body: body, 
        author: values.author, 
        image_url: values.image ? values.image.file.response.data.url : "",
        publish_date: new Date(),
        is_link: visible,
        link: visible === true ? values.link : null
      };

      console.log("Blog", blog)
      blogStore.createBlog(blog)
    }
  
    return (
      <>
        <PageHeader
          className="site-page-header"
          onBack={() => window.history.back()}
          title="Add Blog"
          subTitle="Here you can Add Blog"
        />
        <Divider />
        <div className="">
              <Form
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 12 }}
              >                        
                <Form.Item 
                  label="Title" 
                  name="title"
                  rules={[{ required: true, message: 'Please Enter Blog Title!' }]}
                >
                  <Input placeholder="Enter Blog Title" />
                </Form.Item>
                <Form.Item 
                  label="Image"
                  name="image"
                  rules={[{ required: true, message: 'Please Upload Blog Image!' }]}
                >
                  <Upload {...imageProps}>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label='External link'>
                  <Checkbox onChange={() => setVisible(!visible)}>Yes</Checkbox>
                </Form.Item>
                {visible === true ? (
                  <Form.Item 
                    label="Add Link" 
                    name="link"
                    rules={[{ required: true, message: 'Please Enter Link!' }]}
                  >
                    <Input placeholder="Enter Link" />
                  </Form.Item>
                ) : (
                  <Form.Item 
                    label="Description" 
                    name="body"
                    rules={[{ required: true, message: 'Please Enter Blog Description!' }]}
                  >
                    <CKEditor
                      editor={ ClassicEditor }
                      config={{ 
                        extraPlugins: [uploadPlugin] 
                      }}
                      data="<p>You Can Write Blog Content here.</p>"
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                        });
                      }}
                      onChange={( event, editor ) => {
                        const data = editor.getData();
                        setBody(data);
                        console.log(body);
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item 
                  label="Author" 
                  name="author"
                  rules={[{ required: true, message: 'Please Enter Author Name!' }]}
                >
                  <Input placeholder="Enter Author Name" />
                </Form.Item>
                <Form.Item  wrapperCol={{ span: 12, offset: 4 }}>
                  <Button type="primary" htmlType="submit">Add Blog</Button>
                </Form.Item>
              </Form>
        </div>
      </> 
    );
  }

export default AddBlog;
