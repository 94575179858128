import { makeAutoObservable, runInAction } from "mobx";
import { message } from 'antd';
import agent from "../services/agent";
import { history } from "..";
import { store } from "./store";

export default class UserStore{
    user = null ;

    constructor(){
        makeAutoObservable(this)
    }

    login = async (user) => {
        const data = await agent.user.login(user)
        .then(response => {
            runInAction(() => {            
                if(response.data.message === 'Success'){
                    store.commonStore.setToken(response.data.token)
                    this.user = response.data.user
                    history.push('/home') 
                }
            });
            console.log("response.data",response.data)
        })
        .catch(error => {
            console.log("error", error.response ? error.response : error)
            message.error(`${error.response ? error.response.data.message : "Something went wrong !"}`, 4)
        })
    }
}