import { useHistory } from "react-router-dom";
import { Table, Space, Popconfirm, message } from 'antd';
import { EditIcon, TrashIcon } from '../UI/multiple-icons';
import { useStore } from "../../context/store";


const BlogList = ({ blogs }) => {
  const history = useHistory();
  const { blogStore } = useStore()

  const confirmDelete = (_id) => {
    console.log(_id);
    blogStore.deleteBlog(_id)
  }
  
  const cancelDelete = (e) => {
    console.log(e);
    message.error('Changes Canceled');
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "_id",
      width: 50,
      maxWidth: 90,
      render:  (t, r) => <img src={r.image_url} width="100px" height="80px" alt="Blog" />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "publish_date",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "updated_at",
    },
    {
      title: "Action",
      key: "created_at",
      render: (text, record) => (
        <Space size="middle">
          <span 
            style={{color: "#1890ff", cursor: "pointer"}} 
            onClick={() => { 
              history.push(`/update_blog/${record._id}`)
            }}>
              <EditIcon width={15} />
          </span>
          <Popconfirm
            title="Are you sure to delete this Blog?"
            onConfirm={() => confirmDelete(record._id)}
            onCancel={(e) => cancelDelete(e)}
            okText="Yes"
            cancelText="No"
          >              
            <span style={{color: "crimson", cursor: "pointer"}}>
              <TrashIcon width={15} />
            </span>
          </Popconfirm>
        </Space>        
      ),
    },
  ];

  return ( 
    <>
      <Table 
        dataSource={blogs} 
        columns={columns} 
        pagination={{
          pageSize:6,
        }} 
      />
    </>

  ); 
};

export default BlogList;

