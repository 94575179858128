import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageHeader, Row, Col, Button, Divider, Tree } from 'antd';
import { useStore } from '../../context/store';
import { observer } from 'mobx-react-lite';
import './Categories.css';

const Categories = () => {
    const { categoryStore } = useStore();
    const { TreeNode } = Tree;
    const history = useHistory();

    useEffect(() => {
        categoryStore.loadCategoryList()

    }, []);

    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info); 
        history.push(`/category_update/${info.selectedNodes[0] && info.selectedNodes[0].key}`)
    };
    
    const onCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
    };    

    const RenderTreeNodes = data =>
        data.map(item => {
            if (item.child) {
                return (
                <TreeNode title={item.name} key={item._id} dataRef={item}>
                    {RenderTreeNodes(item.child)}
                </TreeNode>
                )
            }
            return <TreeNode key={item.key} {...item} />
        })

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Categories"
                subTitle="Categories and Subcategories are shown"
                extra={[
                    <Button key="3" type="primary">
                        <Link to="/add_category">
                            Add Category / Subcategory
                        </Link>
                    </Button>
                ]}
            />
            <Divider />
            <Row>
                <Col span={2}></Col>  
                <Col span={20}> 
                    <Tree
                        checkable={false}
                        onSelect={onSelect}
                        onCheck={onCheck}
                    >
                        {RenderTreeNodes(categoryStore.categoriesTree)}
                    </Tree>
                </Col>
            </Row>
        </div>
    )
}

export default observer(Categories);
